<template>
  <div
    id="page-container"
    class="flex min-h-screen flex-col"
  >
    <AppCheckoutHeader :initial-data="initialData" />
    <div class="flex grow flex-col">
      <slot />
    </div>
    <DelayHydration>
      <LazyAppFooter
        :infobox="initialData?.infobox"
        :newsletter="initialData?.newsletter"
        :social="initialData?.social"
        :footer-navigation="initialData?.footerNavigation"
      />
    </DelayHydration>

    <DelayHydration>
      <LazyUiFloatingIcons
        v-show="floatingIcons.length"
        :icons="floatingIcons"
      />
    </DelayHydration>

    <DelayHydration>
      <LazyAppDrawer />
    </DelayHydration>

    <DelayHydration>
      <LazyAppReportErrorModal />
    </DelayHydration>
  </div>
</template>

<script lang="ts" setup>
import AppCheckoutHeader from '@ecom/components/AppCheckoutHeader/AppCheckoutHeader.vue'
import { useFloatingIcons } from '@cms/composables/useFloatingIcons/useFloatingIcons'

const { initialData } = useT3Api()

const { floatingIcons } = useFloatingIcons()
</script>
